<!--
  PACKAGE_NAME : src\pages\esp\hr\user\modal-page-join-user.vue
  FILE_NAME : modal-page-join-user
  AUTHOR : ycnam
  DATE : 2024-07-10
  DESCRIPTION : 사용자 등록 및 수정 모달
-->
<template>
	<div style="overflow-y:auto">
		<DxPopup
            :visible="isOpen"
            :show-title="true"
			ref="userPopup"
			title="사용자 등록"
			min-width="1000"
			width="1000"
			min-height="700"
			height="700"	
            :drag-enabled="true"
            :resize-enabled="true"
            :show-close-button="true"
            :close-on-outside-click="true"		
            @hiding="closeModal"						
		>
			<template>
				<div class="container contents-wrap">
						<div class="page-bin mar_to20 mar_b10"></div>
						<div class="flex flex-col justify-between" style="height=490px;">
							<div>
								<table class="table_form line-bin" ref="scrollContainer" style="max-height=450px !important">
									<colgroup>
										<col style="width:25%" />
										<col style="width:25%" />
										<col style="width:25%" />
										<col style="width:25%" />
									</colgroup>
									<tbody>
										<tr>
											<th scope="row">
												<label for="label5">
													<span>사이트 / 센터 / 팀</span>
													<span class="icon_require">필수항목</span>
												</label>
											</th>
											<td class="clearfix">
												<DxSelectBox
													:items="getSiteList"
													placeholder="선택"
													display-expr="siteNm"
													value-expr="id"
													v-model="formData.siteId"
													:width="200"
													:styling-mode="stylingMode"
												>
													<DxValidator validation-group="validationGroupName">
														<DxRequiredRule message="사이트 선택은 필수입니다." />
													</DxValidator>
												</DxSelectBox>								
											</td>
											<td class="clearfix">
												<DxSelectBox
													:items="getTenantList"
													placeholder="선택"
													display-expr="tenantNm"
													value-expr="id"
													v-model="formData.tenantId"
													:width="200"
													:styling-mode="stylingMode"
												>
													<DxValidator validation-group="validationGroupName">
														<DxRequiredRule message="센터 선택은 필수입니다." />
													</DxValidator>
												</DxSelectBox>								
											</td>
											<td class="clearfix">
												<DxSelectBox
													placeholder="선택"
													display-expr="label"
													value-expr="value"
													:width="200"
													v-model="formData.companyNm"
													:styling-mode="stylingMode"
												>
													<DxValidator validation-group="validationGroupName">
														<DxRequiredRule message="팀 선택은 필수입니다." />
													</DxValidator>
												</DxSelectBox>								
											</td>
										</tr>
										<tr>
											<th scope="row">
												<label for="label5">
													<span>사번</span>
													<span class="icon_require">필수항목</span>
												</label>
											</th>
											<td class="clearfix">
												<input class="table_input" type="text" v-model="formData.userNo">
											</td>
											<th scope="row">
												<label for="label5">
													<span>이름</span>
													<span class="icon_require">필수항목</span>
												</label>
											</th>
											<td>
												<input class="table_input" type="text" v-model="formData.userNm">
											</td>
										</tr>
										<tr>
											<th scope="row">
												<label for="label5">
													<span>내선번호</span>
												</label>
											</th>
											<td>
												<input class="table_input" type="text" v-model="formData.dn">
											</td>
											<th scope="row">
												<label for="label5">
													<span>사용여부</span>
												</label>
											</th>
											<td>
												<DxSwitch
													@value-changed="onChangedViewFl($event)"
													:value="getViewFl"
													switched-off-text="OFF"
													switched-on-text="ON"
													v-model="formData.viewFl"
												/>								
											</td>
										</tr>
										<tr>
											<th scope="row">
												<label for="label5">
													<span>계정 연동</span>
													<span class="icon_require">필수항목</span>
												</label>
											</th>
											<td class="clearfix">
												<DxSwitch
													@value-changed="onChangedViewFl($event)"
													:value="getViewFl"
													switched-off-text="OFF"
													switched-on-text="ON"
												/>								
											</td>
										</tr>										
									</tbody>
								</table>
								<div class="page-bin mar_to20 mar_b10"></div>
							</div>
						</div>
						<div class="page-bin mar_to20 mar_b10"></div>
					</div> 
			</template>
			<DxToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                :visible="true"
                :options="{
                        elementAttr: {
                            class: 'default filled txt_S medium',
                        },
                        text: '저장',
                        width: '120',
                        height: '40',
                        useSubmitBehavior: true,
                        onClick: (e) => {
                            onSaveModal(e);
                        }
                    }"
            />

            <DxToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                :visible="true"
                :options="{
                        elementAttr: {
                            class: 'white filled txt_S medium',
                        },
                        text: '취소',
                        width: '120',
                        height: '40',
                        onClick: () => {
                            closeModal();
                        }
                    }"
            />			
		</DxPopup>
	</div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxSwitch from "devextreme-vue/switch";

export default {
	components: {  DxPopup, DxToolbarItem, DxCheckBox, DxSelectBox, DxDateBox, DxRequiredRule, DxValidator, DxSwitch },
	props: {
		isOpen: {
			default: false,
			type: Boolean,
		},    
		userData : {
			default: () => ({
				rowData: []
			}),				
		}   
	},
	data() {
		return {
			stylingMode: 'outlined',
			formData: {
				id: null,
				userNo: null,
				userNm: null,
				site: null,
				siteId: null,
				siteNm: null,
				tenant: null,
				tenantNm: null,
				tenantId: null,
				ctiFl: false,
				ctiId: null,
				wfmFl: false,
				iptFl: false,
				loginFl: null,
				recFl: 0,
				workDt: null,
				viewFl: 'Y',
			},
			showCti: null,   
			showWfm: false,		
			showIpt: false,		
			showCtiDetail: false,   
			showWfmDetail: false,		
			showIptDetail: false,		
			propsData: {
				userNo: '',
				userNm: '',
				ctiFl: false,
				viewFl: 'Y'
			},
		};
	},
	watch: {
		isOpen() {
			this.propsData = this.userData?.rowData ? this.userData.rowData[0] : {};
			this.formData = this.userData?.rowData ? this.userData.rowData[0] : {};
		}
	},
	computed: {
		getViewFl() {
			return this.formData.viewFl === 'Y' ? 'Y' : 'N';
		},	
		/** @description: U_CODE svr_type 가져오기 */
		getSiteList(){
			const result = this.$store.getters.getSiteList;
			return result;
		},			
		getTenantList(){
			const result = this.$store.getters.getTenantList;
			return result;
		},			
	},
	methods: {
		onChangedViewFl(e) {
		},
		async onSaveModal() {
			let selectedSite = this.$store.getters.getSiteList.filter((v) => v.id === this.formData.siteId)[0];
			let selectedTenant = this.$store.getters.getTenantList.filter((v) => v.id === this.formData.tenantId)[0];
			this.formData.site = selectedSite.id;
			this.formData.siteNm = selectedSite.siteNm;
			this.formData.tenant = selectedTenant.id;
			this.formData.tenantNm = selectedTenant.tenantNm;
			// 시간 형식 변환
			const d = new Date();
			const date = new Date(d.getTime()).toISOString().split('T')[0];
			const time = d.toTimeString().split(' ')[0];
			this.formData.ctiFl = 1;
			this.formData.joinDt = date + ' ' + time;
			this.formData.viewFl = 'Y';
			this.formData.loginFl = 'Y';
			this.formData.recFl = 'N';
			const payload = {
				actionname: 'USER_SAVE',
				data: { 
					from: "core",
					data : [this.formData]
				},
				loading: true,
			};
			const res = await this.CALL_API(payload);
			if (res.status === 200) {
				this.$_Msg('성공');
			} else {
				this.$_Msg('실패');
			}

			this.setInitModal();
		},
        /* @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
        setInitModal() {
			this.$emit('saveModal');			
            this.propsData = {};
        },					
		/** @description : 팝업 취소 버튼 액션  */
		closeModal() {
			this.$emit('closeModal');
			this.propsData = {};
		},		
		onToggle(type) {
			if (type === 'CTI') {
				this.showCtiDetail = this.showCtiDetail ? false : true;
			} else if (type === 'WFM') {
				this.showWfmDetail = this.showWfmDetail ? false : true;
			} else if (type === 'IPT') {
				this.showIptDetail = this.showIptDetail ? false : true;
			}
		},
		onValueChanged(e) {
			if (e.value === true) {
				if (e.element.__vue__.text === 'CTI') {
					this.showCti = true;
				} else if (e.element.__vue__.text === 'WFM') {
					this.showWfm = true;
				} else if (e.element.__vue__.text === 'IPT') {
					this.showIpt = true;
				}
			} else {
				if (e.element.__vue__.text === 'CTI') {
					this.showCti = false;
				} else if (e.element.__vue__.text === 'WFM') {
					this.showWfm = false;
				} else if (e.element.__vue__.text === 'IPT') {
					this.showIpt = false;
				}
			}
		}
	},
	created() {
	},
	mounted() {	
	}
};
</script>

<style lang="scss" scoped>
	::v-deep {
		.dx-popup-content {
			overflow-y: auto !important;
		}

		.table_form {
			height: 100%;
			border: none;
			max-height: 400px;
			overflow-y: auto !important;
		}

		.clearfix {
			margin: 0 auto; // 가운데정렬
			//width: 88%;
		}

		.table_input {
			border : 1px solid #dcdcdc !important; 
			border-radius : 4px;
			width: 200px;
			height: 30px;
		}

		.additional-info {
			background-color: white;
			border-top: 1.5px solid #c2c0c0;
			border-bottom: 1.5px solid #c2c0c0;
		}

		.additional-info th {
			background-color: white;
		}
		
	}
</style>
